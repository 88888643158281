/* eslint-disable max-len */
import React from "react";
import "./diwali-merch-page.m.css";
// import { useUserSubscription } from "../../helper/hooks";
// import { SubscriberOnlyNudge } from "./subscriber-only-nudge";

export function DiwaliMerchPage() {
  const handleBuyNow = (planId) => () => {
    window.open(`https://razorpay.com/payment-button/${planId}/view`, "_blank");
  };

  return (
    <div styleName="wrapper">
      <h1 styleName="title">Diwali with Newslaundry</h1>
      <br />
      <div styleName="top-wrapper">
        <div styleName="img-wrap">
          <img
            alt="banner"
            src="https://images.assettype.com/newslaundry/2021-10/7ffa1158-06a1-457b-84e8-fe0cf42a9901/diwali_1.jpg"
          />
        </div>
        <div styleName="text-wrap">
          <p styleName="description">
            {" "}
            We celebrate festivals for a number of reasons. This year,
            Newslaundry is adding one more to this list:
            <strong>
              <i>The spirit of independent journalism.</i>
            </strong>
            <br />
            <br />
            Explore our merchandise and proudly represent a community that
            powers free, and independent journalism.
            <br />
            <br />
            Or gift these items to friends and family to help spread the word
            about this important mission.
            <br />
          </p>
        </div>
      </div>
      <br />
      <div styleName="hamper__container">
        <div styleName="hamper__card">
          <h2 styleName="hamper__title">Hamper One</h2>
          <img
            alt=""
            // src="https://images.assettype.com/newslaundry/2021-10/c367c9d9-a6ca-42f9-92b3-72df7fb187e7/Diwali_Hamper_1_2__.jpg"
            src="https://images.assettype.com/newslaundry/2023-11/f1b9c8b6-6b23-43e5-a3f6-03cccd8d80ad/DIWALI_HAMPER_ONE_.jpg"
            // style={{ margin: "-90px" }}
          />

          <ul style={{height:"70%"}}>
            <li>Laptop stickers worth 249/-</li>
            <li>Fridge magnet worth ₹399/-</li>
            <li>Naxalbari Comic worth ₹799/-</li>
            <li>I Pay to Keep News Free Backpack  worth ₹549/-</li>
            <li>Mug: Karare Chane worth  ₹349/-</li>
            <li>Free 2 x 1-month subscription worth worth ₹600/-</li>
          </ul>
          <h2 styleName="hamper__price-1">
            <span styleName="rupee_sign">₹</span>2,100
          </h2>
          {/* {subscriptionLoading ? (
            <button disabled={true} aria-disabled="true">
              Loading...
            </button>
          ) : (
            <button onClick={handleBuyNow("pl_IDxBceYSd3ggkJ")}>Buy Now</button>
            )} */}
          <button onClick={handleBuyNow("pl_MvcSqsN7FwZSfx")}>Buy Now</button>
        </div>
        <div styleName="hamper__card">
          <h2 styleName="hamper__title">Hamper Two</h2>
          <img
            alt=""
            // src="https://images.assettype.com/newslaundry/2021-10/ec1a2962-f4e7-479c-a983-9b16c2fbe4fc/Diwali_Hamper_2_2_.jpg"
            src="https://images.assettype.com/newslaundry/2023-11/ec4735f1-2b62-443b-b6a3-28a8f83505b2/DIWALI_HAMPER_TWO_.jpg"
            // style={{ margin: "-75px" }}
          />

          <ul style={{height:"70%"}}>
            <li>Laptop stickers worth 249/-</li>
            <li>Car stickers worth  ₹299/-</li>
            {/* <li>Kashmir Ki Kahani Comic</li> */}
            <li>Fridge magnet worth ₹399/-</li>
            <li>Naxalbari Comic worth ₹799/-</li>
            <li>Kashmir Ki Kahani worth  ₹1499/-</li>
            {/* <li>Car Stickers</li> */}
            <li>Stay Mad or Karare Chane, T-shirt worth ₹699/-</li>
            {/* <li>Which Naxal Are You Mug</li> */}
            <li>I pay to keep news free bag worth ₹549/-</li>
            <li>Mug: Karare Chane worth ₹349/-</li>
            <li>Mug: Which naxal are you? worth  ₹249/-</li>
            <li>Free 5 x 1-month subscriptions worth worth ₹1500/-</li>
          </ul>
          <h2 styleName="hamper__price-2">
            <span styleName="rupee_sign">₹</span>5,000
          </h2>
          {/* {subscriptionLoading ? (
            <button disabled={true} aria-disabled="true">
              Loading...
            </button>
          ) : (
            <button onClick={handleBuyNow("pl_IDxFgSepurUXrE")}>Buy Now</button>
            )} */}
          <button onClick={handleBuyNow("pl_MvcSakF3VlWHmA")}>Buy Now</button>
        </div>
        {/* <div styleName="hamper__card">
          <h2 styleName="hamper__title">Hamper Three</h2>
          <img
            alt=""
            src="https://images.assettype.com/newslaundry/2021-10/acaa0e7a-7c68-4dbb-a6ce-9b20c46843d7/Diwali_Hamper_3_2_.jpg"
            style={{ marginTop: "-5px" }}
          />
          
          <ul>
            <li>Candle</li>
            <li>Fridge Magnets</li>
            <li>Karare Chane Mug</li>
            <li>Tote bag</li>
            <li>Flask</li>
            <li>Laptop Stickers</li>
            <li>Car Stickers</li>
            <li>Naxalbari Comic</li>
            <li>Gift 2x Six Months Disruptor Subscriptions</li>
          </ul>
          <h2 styleName="hamper__price-3">
            <span styleName="rupee_sign">₹</span>2599
          </h2>
          <button onClick={handleBuyNow("pl_IDxIssRYKq1uyV")}>Buy Now</button>
        </div> */}
      </div>
      <div>
        <strong>Terms &amp; Conditions</strong>
      </div>
      <ul styleName="notes_wrapper">
        <li>
          This is a limited period offer till November
          20th, 2022.
        </li>
        <li>Delivery in India only. </li>
        <li>
          If you are based overseas, please share an Indian delivery address (of
          friends/family).
        </li>
        <li>
          Please note, delivery of packages will take at least two to three
          weeks. Please be patient with us.
        </li>
        <li>
          We will manually map the subscription gifted. The subscription will be
          mapped within 48 hours.
        </li>
        <li>
          The product images shown may not be an exact representation of the
          product.
        </li>
        <li>
          If you have any queries, please drop an email on{" "}
          <a style={{ color: "#ec2227" }} href="mailto:store@newslaundry.com">
            store@newslaundry.com
          </a>
        </li>
      </ul>
      <br />
    </div>
  );
}
